// expandable portfolio list
const menu = {
	el: {
		menuItems: '.menu-item',
	},
	init() {
		console.log('here', window.location)
		$(menu.el.menuItems).on('click', menu.changeActive);
	},
	changeActive(ev){
		// event.preventDefault()
		console.log()
		$(menu.el.menuItems).removeClass('active');
      $(this).addClass('active');
	},
}

export default menu;