

// Require all js files needed 
import dev from "./dev";
import portfolio from "./portfolio";
import menu from "./menu";

import respimg from "respimg";
import bgset from "bgset";
import parentfit from 'parent-fit';
import lazysizes from "lazysizes";
import ScrollOut from "scroll-out";

import Flickity from "flickity";


window.lazySizesConfig.init          = false;
window.lazySizesConfig.lazyClass     = 'js-lazy';
// window.lazySizesConfig.minSize       = '400w';
window.lazySizesConfig.expand        = 450;
window.lazySizesConfig.expFactor     = 1.3;
var animText, animFade;


const bellco = {
	// All pagess
	'common': {
		init() {
			dev.grid();
			lazysizes.init();
			ScrollOut({
				
			});
			const flkty = new Flickity( '.slider', {
				cellSelector: 'figure',
				lazyLoad: 3,
				contain: true,
				cellAlign: 'left',
				prevNextButtons: false,
				selectedAttraction: 0.03,
				friction: 0.45,
				pageDots: false,
				adaptiveHeight:false,
			});
		},
		finalize() {}
	},
	'homepage': {
		init() {
			$('#menu-item-1').addClass('active');
		},
	},
	'founders': {
		init() {
			$('#menu-item-2').addClass('active');
		}
	},
	'portfolio': {
		init() {
			$('#menu-item-3').addClass('active');
			portfolio.init();
		}
	},
	'news': {
		init() {
			$('#menu-item-4').addClass('active');
		}
	}
};






/* ========================================================================
* START THE APPLICATION JS FILE
* ======================================================================== */

(function ($) {

	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire: function (func, funcname, args) {
			var fire,
				namespace = bellco;

			funcname = (funcname === undefined) ? 'init' : funcname;
			fire = func !== '';
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === 'function';

			if (fire) {
				namespace[func][funcname](args);
			}
		},

		loadEvents: function () {
			// Fire common init JS
			UTIL.fire('common');
			// get app state then fire related JS

			var app_state = $('body').data('state').replace(/-/g, '_').split(/\s+/)[0];
			if (app_state.length > 0) {
				UTIL.fire(app_state);
				UTIL.fire(app_state, 'finalize');
				console.log('------AppState------', app_state);
			} else {
				console.log('stateless');
			}

			// Fire common finalize JS
			UTIL.fire('common', 'finalize');
		}
	};//end of UTIL

	// Load Events
	$(document).ready(() => {
		UTIL.loadEvents();
		menu.init();
	});

})(jQuery); // Fully reference jQuery after this point.
