// expandable portfolio list
const portfolio = {
	el: {
		title: '.js-company',
	},
	o: {
		open: false,
	},
	init() {
		$(portfolio.el.title).on('click', portfolio.toggleOpen);
	},
	toggleOpen(ev){
		// if not an <a>
		if (ev.target.tagName !== "A") {
			if(portfolio.o.open){
				// close
				$(this).removeClass('open')
				$(this).find('.closed').animate({
					'height': '0px'
				}, 300)
				// hide paper.svg
				$(this).removeClass('paper');
			}else{
				// open
				let height = $(this).find('.closed').children().outerHeight();
				// description & link
				if ($(this).find('.closed').children().length === 2) {
					// margin + padding
					height += 50;
				}
				// description with two sections & link
				else if ($(this).find('.closed').children().length === 3) {
					height += 100;
				}
				$(this).addClass('open');
				$(this).find('.closed').animate({
					'height': `${height}px`
				}, 300)
				// show paper.svg
				$(this).addClass('paper');
			}
			portfolio.o.open = !portfolio.o.open;
		}
	},
}

export default portfolio;